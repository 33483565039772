<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical">
        <sdHeading as="h3">Forgot Password?</sdHeading>
        <p class="forgot-text">
          Enter the email address you used when you joined and we’ll send you instructions to reset your password.
        </p>
        <a-form-item label="Email Address" name="email">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
        </a-form-item>
        <a-form-item>
          <button class="btn-create vue-btn" htmlType="submit" type="primary" size="large"> Send Reset Instructions </button>
        </a-form-item>
        <p class="return-text">Return to <router-link to="/auth/login">Sign In</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive, defineComponent } from 'vue';
import { AuthWrapper } from './style';
import { message, Notification } from "ant-design-vue";
import { useStore } from "vuex";

const ForgotPassword = defineComponent({
  name: 'ForgotPassword',
  components: { AuthWrapper },
  
  setup() {
  const { state, dispatch } = useStore();
    
    const handleSubmit = (values) => {
      //alert(values);
      localStorage.setItem("email", formState.email);
      dispatch("sendPasswordReset", formState).then(
        (response) => {
          if (response.status == 200) {
            //location.reload();
            Notification["success"]({
              message: "Succsess!",
              description: "We have e-mailed your password reset link!.",
            });
            
            
          }
        },
        (error) => {
          if (error.status == 201) {
          Notification["error"]({
              message: "Error!",
              description: "The Email id is not registered.",
            });
          }
        }
      );
    };

    const formState = reactive({
      email: '',
    });

    return {
      handleSubmit,
      formState,
    };
  },
});

export default ForgotPassword;
</script>
